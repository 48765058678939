<script lang="ts">
	import { createEventDispatcher, setContext } from 'svelte'
	import { writable, type Writable } from 'svelte/store'
	import { enhance } from '$app/forms'
	import type { ActionResult } from '@sveltejs/kit'

	export let action: string | null = null
	export let autocomplete = 'on'
	export let cls = ''
	export let reset = true
	export let updatePage = true

	export let formData: Writable<Record<string, any>> = writable({})

	const dispatch = createEventDispatcher()

	let loading = writable(false)
	let fail: Writable<Extract<ActionResult, { type: 'failure' }>> = writable()

	setContext('fail', fail)
	setContext('loading', loading)
	setContext('formData', formData)
</script>

<form
	novalidate
	class={cls}
	method="POST"
	{action}
	{autocomplete}
	use:enhance={() => {
		let loaded = false
		setTimeout(() => {
			if (!loaded) $loading = true
		}, 50)

		return async ({ result, update }) => {
			$loading = false
			loaded = true

			if (updatePage) update({ reset })
			if (['success', 'redirect'].includes(result.type)) dispatch('success', result)
			if (result.type === 'failure') $fail = result
		}
	}}
>
	<slot />
</form>
